@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css?family=Sedgwick+Ave');

#root{
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}

body{
  background-color: black;
  font-family:'TwoltripType', 'Sedgwick Ave';
}

::-webkit-scrollbar {
  background: black;
}

::-webkit-scrollbar-thumb {
  background: #00BFFF;
}

::-webkit-scrollbar-corner {
  background: black
}

@font-face {
  font-family: 'TwoltripType';
  src: url('./Fonts/2ltripType.woff') format('woff'),
  url('./Fonts/2ltripType.woff2') format('woff2'),;
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@layer components {
  .footer{
    height:fit-content;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    height: 2rem;
    background-color: black;
  }
}